import { ref } from 'vue'
import axios from "axios";

const inputRma = (rowData, ket) => {
  const posts = ref(null)
  const error = ref(null)

  const load = async () => {
    console.log('din')
    console.log(rowData)
    const myJson = JSON.stringify(rowData)
    console.log('din    ddd')
    console.log(myJson)
    try {
      let res = await axios.post('https://api.avasoft.co/rma/input',
        {
          'ket': ket,
          'klgn': Object.entries(rowData)[0][1]['klgn'],
          'user': 'web',
          'kcabang': Object.entries(rowData)[0][1]['cabang'],
          'nlgn': Object.entries(rowData)[0][1]['nlgn'],
          'alamat': Object.entries(rowData)[0][1]['alamat'],
          'telp': Object.entries(rowData)[0][1]['telp'],
          'cabang': Object.entries(rowData)[0][1]['ncabang'],
          'rma_det': myJson
        }

      );


      posts.value = ({
        hasil: res.data
      })
    } catch (err) {
      console.log('err')
      console.log(err.message)
      error.value = err.message
    }
  }

  return { error, posts, load }
}

export default inputRma
