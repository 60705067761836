<template>
  <div class="bg-gray-50 pt-28 pb-12">
    <div class="flex flex-col items-center">
      <h1 class="text-center font-bold text-2xl">Cek Status Perbaikan</h1>
      <div class="w-full md:max-w-3xl mx-auto">
        <form
          class="mt-12 gap-2 flex flex-col items-center px-3"
          @submit.prevent="addItem"
        >
          <input
            class="w-full md: bg-white border border-gray-400 rounded  py-3 px-3 text-gray-800 focus:ring-1 focus:ring-turkis outline-none "
            type="text"
            placeholder="Nomor Perbaikan/Serial Number..."
            v-model="serialNumber"
          >
          <button class="px-3 py-2 w-full md:w-72 text-white font-semibold shadow-md  rounded bg-turkis">Cek</button>
        </form>

      </div>
      <div
        v-if="error"
        class=text-red-900
      >{{ error }}</div>

      <div class="flex flex-col gap-8 w-full md:w-9/12 items-end my-8 px-3">
        <div class="mt-12 bg-white">
          <table class="border border-gray-500 w-full table-fixed">
            <thead>
              <tr class="text-left border bg-turkis text-white font-bold text-xs border-turkis align-top">
                <th class="w-1/5 py-3 pl-2">Nomor Perbaikan</th>
                <th class="w-1/5 py-3 pl-2">Kode Barang</th>
                <th class="w-1/5 py-3 pl-2">Serial Number</th>
                <th class="w-1/5 py-3 pl-2">Nama Barang</th>
                <th class="w-1/5 py-3 pl-2">Status</th>
              </tr>
            </thead>
            <tbody>
              <tr
                class="text-left even:bg-gray-200 border-gray-500 py-2 align-top"
                v-for="item in rowData"
                :key="item.serial"
              >
                <td class="px-2 break-all">{{item.no_fak}}</td>
                <td class="px-2 break-all">{{item.kbrg}}</td>
                <td class="px-2 break-all">{{item.serial}}</td>
                <td class="px-2 break-all">{{item.nbrg}}</td>
                <td class="px-2 break-all">{{item.status_barang}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import getComplain from "../composables/getComplain";

export default {
  data() {
    return {
      serialNumber: "",
      rowData: [],
      error: "",
    };
  },

  methods: {
    async addItem() {
      const { error, posts, load } = getComplain(this.serialNumber);
      await load();
      if (!posts.value) {
        this.error = "Tidak ada data";
      } else {
        this.error = error;
      }

      this.rowData = posts;
      this.serialNumber = "";
    },
  },
};
</script>
