<template>
  <div class="bg-gray-50 pt-28 pb-12">
    <div class="flex flex-col items-center">
      <h1 class="text-center font-bold text-2xl">Input Serial Number </h1>
      <div class="w-full md:max-w-3xl mx-auto">
        <form
          class="mt-12 gap-2 flex flex-col items-center px-3"
          @submit.prevent="addItem"
        >
          <div class="w-full">
            <input
              class="w-full md: bg-white border border-gray-400 rounded  py-3 px-3 text-gray-800 focus:ring-1 focus:ring-turkis outline-none "
              type="text"
              placeholder="Serial Number"
              v-model="serial"
            >
          </div>
          <div class="w-full">
            <input
              class="w-full md: bg-white border border-gray-400 rounded  py-3 px-3 text-gray-800 focus:ring-1 focus:ring-turkis outline-none "
              type="text"
              placeholder="Keterangan Kerusakan"
              v-model="problem"
            >
          </div>
          <div class="w-full">
            <input
              class="w-full md: bg-white border border-gray-400 rounded  py-3 px-3 text-gray-800 focus:ring-1 focus:ring-turkis outline-none "
              type="text"
              placeholder="Kelengkapan"
              v-model="kelengkapan"
            >
          </div>
          <button class="px-3 py-2 w-full md:w-72 text-white font-semibold shadow-md  rounded bg-turkis">Tambah</button>
        </form>
        <div
          v-if="error"
          class=text-red-900
        >{{ error }}</div>
      </div>

      <div class="flex flex-col gap-8 w-full md:w-9/12 items-end my-8 px-3">
        <div class="mt-12 bg-white">
          <table class="border border-gray-500 w-full table-fixed">
            <thead>
              <tr class="text-left border bg-turkis text-white font-bold text-xs border-turkis align-top">
                <th class="w-1/6 py-3 pl-2">Serial Number</th>
                <th class="w-1/6 py-3 pl-2">Kode Barang</th>
                <th class="w-1/6 py-3 pl-2">Nama Barang</th>
                <th class="w-1/6 py-3 pl-2">Masa Garansi</th>
                <th class="w-1/6 py-3 pl-2">Kerusakan</th>
                <th class="w-1/6 py-3 pl-2">Kelengkapan</th>
                <th class="w-1/6 py-3 pl-2">Nama Customer</th>
              </tr>
            </thead>
            <tbody>
              <tr
                class="text-left even:bg-gray-200 border-gray-500 py-2 align-top"
                v-for="item in rowData"
                :key="item.serial"
              >
                <td class="px-2 break-all ">{{ item.serial }}</td>
                <td class="break-all px-2">{{ item.k_brg }}</td>
                <td class="break-all px-2">{{ item.nbrg }}</td>
                <td v-bind:style="item.warranty_status == 0 ? 'color:red;':'border:none;'">{{ formatDate(item.tglakhir) }}</td>
                <td class="break-all px-2">{{ item.problem }}</td>
                <td class="break-all px-2">{{ item.kelengkapan }}</td>
                <td class="break-all px-2">{{ item.nlgn }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <table class="w-full">
          <tr>
            <th class="w-1/2">
              <textarea
                class="w-full"
                v-model="message"
                placeholder="Note : Silahkan isi Nama, Alamat, no Hp jika Detail di atas Berbeda"
              ></textarea>

            </th>
            <th class="w-1/4">
              <button
                class="px-3 py-2 w-32 text-white font-semibold shadow-md  rounded bg-turkis"
                v-on:click="handleReset"
              > Reset </button>
            </th>
            <th class="w-1/4">
              <button
                class="px-3 py-2 w-32 text-white font-semibold shadow-md  rounded bg-turkis"
                v-on:click="handleSubmit"
              > Submit </button>
            </th>
            <th>
              <loading
                v-model:active="isLoading"
                :can-cancel="true"
                :on-cancel="onCancel"
              />
            </th>

          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import getSerial from "../composables/getSerial";
import inputRma from "../composables/inputRma";
import Navbar from "../components/Navbar.vue";
import Footer from "../components/Footer.vue";
import moment from "moment";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import "jspdf-autotable";
import getComplain from "../composables/getComplain";
import printJS from "../composables/printJS";

export default {
  name: "Home",
  components: { Navbar, Footer, Loading },
  setup() {},

  data() {
    return {
      serial: "",
      kelengkapan: "",
      problem: "",
      rowData: [],
      error: "",
      info: null,
      isLoading: false,
      message: "",
    };
  },
  methods: {
    async addItem() {
      this.isLoading = true;
      this.error = "";
      let tmpserial = "";
      let tmpCustomer = "";
      for (let i = 0; i < this.rowData.length; i++) {
        if (tmpserial == "") {
          tmpserial = this.rowData[i]["serial"];
          tmpCustomer = this.rowData[i]["klgn"];
        }
      }
      if (tmpserial == this.serial || this.serial == "") {
        this.error = "Serial Sudah di input / Kosong";
        this.isLoading = false;
      }
      if (!this.error) {
        const { error, posts, load } = getSerial(this.serial);
        await load();

        if (posts.value) {
          if (posts._value.length) {
            if (tmpCustomer) {
              if (tmpCustomer != posts._value[0]["klgn"]) {
                this.error =
                  "Maaf data Customer SN yang diinput berbeda dengan data SN pertama, Mohon cek kembali";
              }
            }
            var myObject = {
              serial: posts._value[0]["serial"],
              k_brg: posts._value[0]["kbrg"],
              nbrg: posts._value[0]["nbrg"],
              tglakhir: posts._value[0]["tglakhir"],
              alamat: posts._value[0]["alamat"],
              telp: posts._value[0]["telp"],
              cabang: posts._value[0]["cabang"],
              garansi: posts._value[0]["garansi"],
              klgn: posts._value[0]["klgn"],
              no_jual: posts._value[0]["no_fak"],
              ncabang: posts._value[0]["ncabang"],
              nlgn: posts._value[0]["nlgn"],
              sat: posts._value[0]["sat"],
              status: posts._value[0]["status"],
              tgl_jual: posts._value[0]["tglfak"],
              problem: this.problem,
              kelengkapan: this.kelengkapan,
              warranty_status: posts._value[0]["masihgaransi"],
            };
            if (!this.error) {
              if (posts._value[0]["masihgaransi"] != 1) {
                var katakata =
                  "Data SN yang Anda input sudah diluar masa garansi, yaitu berakhir pada " +
                  posts._value[0]["tglakhir"] +
                  ". Anda akan dikenakan biaya perbaikan yang besaranya akan diinfo lebih lanjut oleh team kami. Klik OK jika setuju";
                if (confirm(katakata)) {
                  this.rowData.push(myObject);
                  this.serial = "";
                  this.problem = "";
                  this.kelengkapan = "";
                }
              } else {
                this.rowData.push(myObject);
                this.serial = "";
                this.problem = "";
                this.kelengkapan = "";
              }
            }
          } else {
            this.error =
              "Maaf Serial tidak terdaftar /sudah ada pada data RMA, silahkan cek kembali";
          }
        } else {
          this.error =
            "Maaf Serial tidak terdaftar /sudah ada pada data RMA, silahkan cek kembali";
        }
        this.isLoading = false;
      }
    },
    async handleSubmit() {
      if (this.rowData.length > 0) {
        this.isLoading = true;
        const { error, posts, load } = inputRma(this.rowData, this.message);
        await load();
        let str = posts.value.hasil;
        if (str.substring(0, 1) == "Z" && str.length == 10) {
          const { error, posts, load } = getComplain(str);
          await load();
          alert(
            "Data berhasil disimpan, silahkan cetak Tanda Pengiriman Service berikut dan ditempel di fisik barang yang akan dikirim ke RMA"
          );

          const { error2, posts2, load2 } = printJS(posts);
          await load2();
          this.rowData = [];
          this.message = "";
        } else {
          this.error = str;
        }
        this.isLoading = false;
      } else {
        this.error = "Data Kosong";
      }
    },
    async handleReset() {
      console.log("handleReset");
      this.rowData = [];
    },
    async handleData() {
      console.log("handleData");

      const { error, posts, load } = getComplain("Z210900003");
      await load();
      const myJson = JSON.stringify(posts.value);
      console.log(myJson);
    },
    async handlePrintJS() {
      console.log("PrintJS");
      const { error, posts, load } = getComplain("Z211000018");
      await load();

      const { error2, posts2, load2 } = printJS(posts);
      await load2();
      console.log(error2);
    },
    onCancel() {
      console.log("User cancelled the loader.");
    },
    formatDate(value) {
      if (value) {
        return moment(String(value)).format("DD MMM yy");
        // return moment(String(value)).format();
      }
    },
  },
};
</script>
