<template>
  <div class="w-full bg-white border-b-2 px-6 py-3">
    <div class="flex justify-between max-w-5xl items-end mx-auto ">
      <div>
        <a class=" items-center " href="https://www.sejahteramandiri.com/">
          <div>
            <img class="object-contain h-12 w-full"
              src="@/assets/logo-sejahtera-mandiri.png"
              alt="logo"
            >
          </div>
          <div class="mt-2 hidden md:flex text-xs font-extrabold text-gray-700">
            RMA Sejahtera Mandiri Perkasa
          </div>
        </a>
      </div>
      <div class="flex gap-3 font-bold">
        <router-link
          @click="scrollToTop"
          class="hover:text-turkis focus:text-turkis "
          to="/"
        >Kirim Garansi</router-link>
        <router-link
          @click="scrollToTop"
          class="hover:text-turkis focus:text-turkis "
          to="/CheckStatus"
        >Check Status</router-link>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  methods: {
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
  },
};
</script>

<style>
</style>