import { ref } from 'vue'
import jsPDF from "jspdf";
import "jspdf-autotable";

const printJS = (posts) => {
  const posts2 = ref(null)
  const error2 = ref(null)

  const load2 = async () => {
    try {
      var doc = new jsPDF("p", "pt");

      doc.setFontSize(10);
      doc.text("Jl Tiang Bendera V", 120, 50);
      doc.text("Ruko Plaza Kota Blok. B No. 10", 120, 65);
      doc.text("Jakarta Barat 11230", 120, 80);
      doc.text("021 - 2607 2204", 120, 95);

      doc.text("Customer", 50, 120);
      doc.text("Alamat", 50, 140);
      doc.text("Telp", 50, 190);

      doc.text(": " + posts.value[0].nlgn, 120, 120);
      doc.text(": " + posts.value[0].alamat, 120, 140, { maxWidth: 250 });
      doc.text(": " + posts.value[0].telp, 120, 190);

      doc.setFontSize(14);
      doc.text(posts.value[0].tglfak, 520, 120, "right");
      doc.text(posts.value[0].no_fak, 520, 150, "right");

      doc.text("TANDA PENGIRIMAN SERVICE", 320, 75);
      const logo = require("@/assets/logo-sejahtera-mandiri.png");
      doc.addImage(logo, "PNG", 20, 50, 90, 40);

      var columns = [
        { title: "Type", dataKey: "kbrg" },
        { title: "Serial Number", dataKey: "serial" },
        { title: "Kelengkapan", dataKey: "kelengkapan" },
        { title: "Kerusakan", dataKey: "problem" },
      ];
      var row = JSON.parse(JSON.stringify(posts.value))

      doc.autoTable(columns, row, { startY: 200 });
      doc.setFont("helvetica", "italic");
      doc.setFontSize(8);
      doc.text(
        "Tanda Terima",
        450,
        doc.internal.pageSize.height - 100,
        "center"
      );
      doc.text(
        "Hormat Kami",
        525,
        doc.internal.pageSize.height - 100,
        "center"
      );
      doc.text("Notes " + posts.value[0].ket, 50, doc.internal.pageSize.height - 100);
      doc.text("Keterangan Barang ", 50, doc.internal.pageSize.height - 80);
      doc.text(
        "1. Barang ini adalah benar pembelian dari PT. Sejahtera Mandiri Perkasa",
        50,
        doc.internal.pageSize.height - 70
      );
      doc.text(
        "2. Surat Jalan ini digunakan hanya untuk kepentingan PT. Sejahtera Mandiri Perkasa",
        50,
        doc.internal.pageSize.height - 60
      );
      doc.save("a4.pdf");
      console.log('ke save')

    } catch (err) {
      error2.value = err.message
    }
  }

  return { error2, posts2, load2 }
}

export default printJS