<template>
  <div class="w-full bg-gray-900 py-5 px-3">
    <div class="flex items-center justify-center flex-wrap gap-3 text-turkis font-semibold text-xs md:text-sm mx-auto py-4 px-3">
      <div>
        Hikvision
      </div>
      <div>
        Proview
      </div>
      <div>
        Huawei
      </div>
      <div>
        Utepo
      </div>
      <div>
        Chuango
      </div>
      <div>
        Juancloud
      </div>
    </div>
    <p class="text-gray-400 text-center text-xs mt-8">© Copyright 2021 PT Sejahtera Mandiri Perkasa. All Rights Reserved.</p>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>