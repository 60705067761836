import { ref } from 'vue'
import axios from "axios";
import qs from 'qs';

const getComplain = (id) => {
  const posts = ref(null)
  const error = ref(null)

  const load = async () => {
    try {
      let res = await axios.post('https://api.avasoft.co/rma/cekComplain', { 'serial': id });

      posts.value = res.data.rma.map(post => ({
        serial: post.SERIAL,
        kbrg: post.K_BRG,
        nbrg: post.N_BRG,
        tglakhir: post.TGL_AKHIR,
        alamat: post.ALAMAT,
        telp: post.TELP,
        cabang: post.CABANG,
        garansi: post.GARANSI,
        klgn: post.K_LGN,
        no_fak: post.NO_FAK,
        ncabang: post.N_CABANG,
        nlgn: post.N_LGN,
        sat: post.SAT,
        status: post.STATUS,
        tglfak: post.TGL_FAK,
        masihgaransi: post.MASIH_GARANSI,
        status_barang: post.STATUS_BARANG,
        problem: post.PROBLEM,
        kelengkapan: post.KELENGKAPAN,
        ket: post.KET,
      }))
    } catch (err) {
      error.value = err.message
    }
  }

  return { error, posts, load }
}

export default getComplain